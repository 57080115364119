import React, { useState, useEffect } from "react";
import "../styles/Footer.css";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { SiWechat, SiTwitter } from "react-icons/si";
import { TiSocialInstagram } from "react-icons/ti";

function Footer({ dark, promo }) {
  return (
    <div className={`${dark ? "promo-footer" : ""}`}>
      <div className={`social-media ${dark ? "dark-footer" : ""}`}>
        <a
          href="http://volpone-wechat.surge.sh/"
          target="_blank"
          className="wechat"
        >
          <SiWechat className="social-icon wechat" />
        </a>
        <a href="" className="facebook">
          <FaFacebookF className="social-icon facebook" />
        </a>
        <a href="" className="instagram">
          <TiSocialInstagram className="social-icon instagram" />
        </a>
        <a href="" className="twitter">
          <SiTwitter className="social-icon twitter" />
        </a>
      </div>
      <div className="footer-container">
        <div className="terms">
          <li>
            <Link to="/privacy-policy" className="link privacy-policy">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/terms-and-conditions" className="link terms-condition">
              Terms and Conditions
            </Link>
          </li>
          <li>
            <Link to="/cookies-policy" className="link cookies">
              Cookies Policy
            </Link>
          </li>
        </div>
        <div className="copyright">
          Copyright &#169; 2020 Volpone. &nbsp;All Rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default Footer;
