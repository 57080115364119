import React, { useEffect, useRef, useState } from "react";
import ReactDom from "react-dom";
import "../styles/ChatWindow.css";
// import io from "socket.io-client";
import {RiSendPlaneFill} from 'react-icons/ri';
import BeatLoader from "react-spinners/BeatLoader";
// import ChatHelper from "../ChatHelper";
import { HiOutlineEmojiSad } from "react-icons/hi";
// import firebase from "../Firebase";
import useLocalStorage from "../hooks/useLocalStorage";
import firebase from "../Firebase";
import moment from 'moment';
const audio = new Audio();


function ChatWindow({ closeChat, person, socket }) {
  const [connected, setConnected] = useState(false);
  const [noAnswer, setNoAnswer] = useState(false);
  const [message, setMessage] = useState('');
  const [conversation, setConversation] = useState([]);
  const chatBoxRef = useRef(null)
  // const convos = firebase.firestore().collection("Conversations");

  useEffect(() => {
    socket.on('accept', (person) => {
      setConnected(true);
    })

    socket.on("notOnline", async (data) => {
      const missedCallers = await firebase.firestore().collection("MissedCalls");
        person.timestamp = moment().format('MM-DD-YY HH:mm');
        missedCallers.doc(person.socketID)
        .set(person)
        .catch(err => {
          console.error(err);
        })
      socket.emit('addMissedCall', data);
      setNoAnswer(true);
    })

    socket.on('cancelCall', (person) => {
      setNoAnswer(true);
    });

    socket.on('customer-recieve-message', (msg) => {
      setConversation((prevState) => {
        return [...prevState, msg]
      })
    })
  }, [socket]);

  useEffect(() => {
    chatBoxRef.current.scrollIntoView();
  }, [conversation]);

  let disconnectChat = () => {
      if (window.confirm("Exit out of chat?")) {
        closeChat(person);
        socket.emit("disconnectChat");
      }
  }

  const handleMessageChange = e => {
    setMessage(e.target.value);
  };

  let submitMsg = (e) => {
    e.preventDefault();
    // ChatHelper.sendMsg(selectedPerson, true, message);
    let msg = { sender: false, text: message, time: moment().format('MM-DD-YY HH:mm'), socketID: person.socketID, server: false }
    // let tmp = conversations.map((customer) => {
    //   if(customer.socketID === selectedPerson.socketID){
    //     customer.messages.push(msg)
    //   }
    //   return customer
    // })
    socket.emit('customer-send-message', msg);
    setConversation((prevState) => {
      return [...prevState, msg]
    })
    setMessage("")
  }

  return (
    <>
      <div className="chat-window">
        <div
          className="close-chat"
          onClick={disconnectChat}>
          X
        </div>
        <div className="chat-title">Volpone Chat</div>
        <div className="chat-container">
          {
            connected 
            ?
            <div className="chat-body">
              <div className="system-msg">
                <div className="dark">Please do not refresh the page. If you get disconnected you may connect with us again at anytime.</div>
                <div className="light">An agent has entered the room.</div>
              </div>
              {
              conversation.map((msg, i) => {
                return (
                  <div className={!msg.sender ? "msg-container sender" : "msg-container recipient"}>
                    <div key={i} className="msg-text"
                      id={!msg.server ? "server-msg" : ""}>
                      {msg.text}
                    </div>
                    {
                      !msg.sender 
                      ?
                    <div className="msg-info">
                      <span>{msg.time}</span> <span>{person.name}</span>
                    </div>
                    :
                    <div className="msg-info">
                      <span>{msg.time}</span> <span>Volpone&nbsp;&nbsp;</span>
                    </div>
                    }
                  </div>
                )
              })
              }
              <div ref={chatBoxRef} className="dummy-div"></div>
            </div>
            :
            noAnswer
            ?
            <div ref={chatBoxRef} className="no-answer">
              <div className="sorry">Sorry!</div>
              <HiOutlineEmojiSad className="sad-face"/>
              <p>Currently all representatives are busy,&nbsp; please try again later.</p> 
            </div>
            :
            <div ref={chatBoxRef} className="waiting">
              A representative will be with you shortly.
              <BeatLoader color={"rgb(45, 107, 215)"} loading={true} size={25} />
              <div>(Approximate less than 3 minute wait)
                <p>Please do not refresh or close this window.</p>
              </div>
            </div>
          }
        </div>
        <form className="chat-input" onSubmit={submitMsg}>
          <input disabled={!connected} type="text" onChange={handleMessageChange} value={message} />
          <button type="submit" disabled={!message} className="send-message"><RiSendPlaneFill /></button>
        </form>
      </div>
    </>
  );
}
 export default ChatWindow;