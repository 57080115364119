import React, { useEffect, useState } from "react";
import { Power2, TweenMax } from "gsap";
import $ from "jquery";
import { FaAngleDoubleUp } from "react-icons/fa";
import "../styles/Products.css";
import ItemsPage from "./ItemsPage";
import Footer from "./Footer";

function Products() {
  const [scrollTop, setScrollTop] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    TweenMax.staggerFrom(
      $(".product-title"),
      1.35,
      {
        y: "200%",
        ease: Power2.easeOut,
        delay: 0.3
      },
      0.5
    );
    window.addEventListener("scroll", scrollEffect);
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", scrollEffect);
    };
  }, []);

  const scrollEffect = () => {
    const checkpoint = 800;
    const currentScroll = window.pageYOffset;
    let opacity;
    if (currentScroll <= checkpoint) {
      opacity = 0 + currentScroll / checkpoint;
    } else {
      opacity = 1;
    }
    document.querySelector(".curtain").style.opacity = opacity;
  };

  let checkScrollTop = () => {
    if (window.scrollY >= 800) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  };

  let scrollToTop = () => {
    window.scroll({ top: $(window).height() - 55, behavior: "smooth" });
  };

  return (
    <>
      <div className="products-container"></div>
      <div className="product-title-container">
        <div className="line-wrapper">
          <div className="product-title">Excellence</div>
        </div>
        <div className="line-wrapper">
          <div className="product-title sub">Quality you can trust</div>
        </div>
      </div>
      <div className="product-title-2">Household Goods</div>
      <div className="curtain"></div>
      <div className="clear-window"></div>
      <div
        className={scrollTop ? "scrollTop active" : "scrollTop"}
        onClick={scrollToTop}
      >
        <FaAngleDoubleUp />
      </div>
      <ItemsPage />
      <Footer />
    </>
  );
}

export default Products;
