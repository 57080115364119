import React, { useEffect, useRef, useState } from "react";
import "../styles/Products.css";
import Card from "./Card";
import moment from "moment";
// import momentTz from "moment-timezone";
var momentTz = require("moment-timezone");

function ItemsPage() {
  const [show, setShow] = useState("");

  useEffect(() => {
    setShow("all");
  }, []);

  let changeTab = e => {
    setShow(e.target.getAttribute("name"));
  };

  let kitchen = [
    {
      img: {
        white:"./productphotos/spicerack.jpg",
        black: "./productphotos/spicerack-black.jpg",
      },
      title: "Spice Rack",
      description: "2 Pack - Magnetic Rack",
      price: {
        white: "26.99",
        black: "22.99",
      },
      link: {
        white: "https://www.amazon.com/VOLPONE-Magnetic-Removable-Refrigerator-Organizer/dp/B08CY3T12N?ref_=ast_sto_dp",
        black: "https://www.amazon.com/VOLPONE-Magnetic-Removable-Refrigerator-Organizer/dp/B08CY3STND?ref_=ast_sto_dp&th=1"
      },  
      resize: "",
      uniqID: 2
    },
    {
      img: {
        black: "./productphotos/spicerack-black-1pk.jpg",
      },
      title: "Spice Rack",
      description: "2 Pack - Magnetic Rack",
      price: {
        black: "16.99",
      },
      link: {
        black: "https://www.amazon.com/VOLPONE-Magnetic-Removable-Refrigerator-Organizer/dp/B08K332P3H?ref_=ast_sto_dp&th=1"
      },  
      resize: "",
      uniqID: 27
    },
    {
      img: {
        black: "./productphotos/microwave-shelf-black.jpg",
        white: "./productphotos/microwave-shelf-white.jpg",
      },
      title: "Microwave Shelf",
      description: "Microwave/Oven Rack",
      price: {
        black: "39.99",
        white: "45.99"
      },
      link: {
        black: "https://www.amazon.com/VOLPONE-Microwave-Assemblely-Required-Organizer/dp/B08NDRK6M2?ref_=ast_sto_dp&th=1",
        white: "https://www.amazon.com/VOLPONE-Microwave-Assemblely-Required-Organizer/dp/B08NDSDZLM?ref_=ast_sto_dp&th=1"
      },  
      resize: "",
      uniqID: 29
    }
  ];
  let bathroom = [
    {
      img: "./productphotos/foldable-towel-rack.jpg",
      title: "Foldable Towel Rack - Matte Black",
      description: "Compact Shelf with Hooks",
      price: "39.99",
      link:
        "https://www.amazon.com/VOLPONE-Foldable-Mounted-Adjustable-Bathroom/dp/B08NGC15ZR",
      resize: "",
      uniqID: 30
    },
    {
      img: {
        black: "./productphotos/diamondcornerblack2pk.jpg",
        white: "./productphotos/diamondcornerwhite2pk.jpg",
      },
      title: "Diamond Shower Caddy",
      description: "2 Tier Corner Shelf",
      price: {
        black: "25.99",
        white: "25.99",
      },
      link: {
        black:
          "https://www.amazon.com/VOLPONE-Removable-Organizer-Bathroom-Kitchen%EF%BC%88White/dp/B08GS271LZ?ref_=ast_sto_dp&th=1",
        white:
          "https://www.amazon.com/VOLPONE-Removable-Organizer-Bathroom-Kitchen%EF%BC%88White/dp/B08GS813RT?ref_=ast_sto_dp&th=1"
      },
      uniqID: 26
    },
    {
      img: {
        white: "./productphotos/diamondcornerwhite.jpg",
      },
      title: "Diamond Shower Caddy",
      description: "1 Tier Corner Shelf",
      price: {
        white: "Out of stock",
      },
      link: {
        white:
          "https://www.amazon.com/VOLPONE-Removable-Organizer-Bathroom-Kitchen%EF%BC%88White/dp/B08GSB6R41?ref_=ast_sto_dp&th=1"
      },
      uniqID: 25
    },
    {
      img: {
        black: "./productphotos/11.8black2pk.jpg",
      },
      title: "11.8in Shower Caddy",
      description: "2 Tier Bath/Kitchen",
      price: {
        black: "15.99",
      },
      link: {
        black:
          "https://www.amazon.com/VOLPONE-Rustproof-Organizer-Drilling-Kitchen%EF%BC%8815-8/dp/B08LKRJ2F5?ref_=ast_sto_dp&th=1"
      },
      uniqID: 24
    },
    {
      img: {
        black: "./productphotos/15.8black2pk.jpg",
      },
      title: "15.8in Shower Caddy",
      description: "2 Tier Bath/Kitchen",
      price: {
        black: "18.99",
      },
      link: {
        black:
          "https://www.amazon.com/VOLPONE-Rustproof-Organizer-Drilling-Kitchen%EF%BC%8815-8/dp/B08LKJLVR1?ref_=ast_sto_dp&th=1"
      },
      uniqID: 23
    },
    {
      img: {
        black: "./productphotos/15.8black1pk.jpg",
      },
      title: "15.8in Shower Caddy",
      description: "1 Tier Bath/Kitchen",
      price: {
        black: "12.99",
      },
      link: {
        black:
          "https://www.amazon.com/VOLPONE-Rustproof-Organizer-Drilling-Kitchen%EF%BC%8815-8/dp/B08LKXX1HX?ref_=ast_sto_dp&th=1"
      },
      uniqID: 22
    },
    {
      img: {
        white: "./productphotos/17.5white1pk.jpg",
      },
      title: "17.5in Shower Caddy",
      description: "1 Tier Bath/Kitchen",
      price: {
        white: "18.99",
      },
      link: {
        white:
          "https://www.amazon.com/VOLPONE-Adhesive-Bathroom-Drilling-Organizer/dp/B08GRZFX6H?ref_=ast_sto_dp&th=1"
      },
      uniqID: 21
    },
    {
      img: {
        black: "./productphotos/17.5black2pk.jpg",
        white: "./productphotos/17.5white2pk.jpg",
      },
      title: "17.5in Shower Caddy",
      description: "2 Tier Bath/Kitchen",
      price: {
        white: "27.99",
        black: "27.99",
      },
      link: {
        white:
          "https://www.amazon.com/VOLPONE-Adhesive-Bathroom-Drilling-Organizer/dp/B08GSBN999?ref_=ast_sto_dp&th=1",
        black:
          "https://www.amazon.com/VOLPONE-Adhesive-Bathroom-Drilling-Organizer/dp/B08GRYTJ9V?ref_=ast_sto_dp&th=1"
      },
      uniqID: 20
    },
    {
      img: {
        white: "./productphotos/13.8white2pk.jpg",
        black: "./productphotos/13.8black2pk.jpg",
      },
      title: "13.8in Shower Caddy",
      description: "2 Tier Bath/Kitchen",
      price: {
        white: "24.99",
        black: "23.99",
      },
      link: {
        white:
          "https://www.amazon.com/VOLPONE-Adhesive-Bathroom-Drilling-Organizer/dp/B08PBSKVP2?ref_=ast_sto_dp&th=1",
        black:
          "https://www.amazon.com/VOLPONE-Adhesive-Bathroom-Drilling-Organizer/dp/B08GS3JV5C?ref_=ast_sto_dp&th=1"
      },
      uniqID: 19
    },
    {
      img: {
        white: "./productphotos/13.8white1pk.jpg",
      },
      title: "13.8in Shower Caddy",
      description: "1 Tier Bath/Kitchen",
      price: {
        white: "17.99",
      },
      link: {
        white:
          "https://www.amazon.com/VOLPONE-Adhesive-Bathroom-Drilling-Organizer/dp/B08GRQ9MGM?ref_=ast_sto_dp&th=1",
      },
      uniqID: 18
    },
    {
      img: {
        black: "./productphotos/organizer-shelf-black.jpg",
        blue: "./productphotos/organizer-shelf-blue.jpg",
        green: "./productphotos/organizer-shelf-green.jpg",
        pink: "./productphotos/organizer-shelf-pink.jpg"
      },
      title: "Adhesive Bathroom Organizer",
      description: "Phone Holder, Towel Bar, Magnetic Bar Soap",
      price: {
        black: "28.99",
        blue: "28.99",
        green: "25.99",
        pink: "28.99"
      },
      link: {
        black:
          "https://www.amazon.com/Adhesive-Bathroom-Volpone-Organizer-Drilling/dp/B08JK9BJ6P?ref_=ast_sto_dp&th=1",
        blue:
          "https://www.amazon.com/Adhesive-Bathroom-Volpone-Organizer-Drilling/dp/B08JKDD98P?ref_=ast_sto_dp&th=1",
        green:
          "https://www.amazon.com/Adhesive-Bathroom-Volpone-Organizer-Drilling/dp/B08JK89RZX?ref_=ast_sto_dp&th=1",
        pink:
          "https://www.amazon.com/Adhesive-Bathroom-Volpone-Organizer-Drilling/dp/B08JK9PQJB?ref_=ast_sto_dp&th=1"
      },
      uniqID: 17
    },
    {
      img: {
        black: "./productphotos/cornercaddy-black.jpg",
        silver: "./productphotos/cornercaddy-silver.jpg",
        white: "./productphotos/cornercaddy-white.jpg",
        silverUpgraded: "./productphotos/cornercaddy-silver-upgrade.jpg"
      },
      title: "Corner Shower Caddy",
      description: "2 Pack",
      price: {
        black: "23.99",
        silver: "19.99",
        white: "23.99",
        silverUpgraded: "23.99"
      },
      link: {
        black:
          "https://www.amazon.com/Bathroom-Self-Adhesive-Removable-VOLPONE-Organizer/dp/B086V5Y2FK?ref_=ast_sto_dp",
        silver:
          "https://www.amazon.com/Bathroom-Self-Adhesive-Removable-VOLPONE-Organizer/dp/B086TYYK4T?ref_=ast_sto_dp&th=1",
        white:
          "https://www.amazon.com/Bathroom-Self-Adhesive-Removable-VOLPONE-Organizer/dp/B086TZCB71?ref_=ast_sto_dp&th=1",
        silverUpgraded:
          "https://www.amazon.com/Bathroom-Self-Adhesive-Removable-VOLPONE-Organizer/dp/B087B8FJW2?ref_=ast_sto_dp&th=1"
      },
      uniqID: 3
    },
    {
      img: "./productphotos/cornercaddy-silver-3t.jpg",
      title: "Corner Shower Caddy - Silver",
      description: "3 Pack",
      price: "31.99",
      link:
        "https://www.amazon.com/Bathroom-Self-Adhesive-Removable-VOLPONE-Organizer/dp/B086TXFVLY?ref_=ast_sto_dp&th=1",
      resize: "",
      uniqID: 4
    },

    {
      img: {
        black: "./productphotos/bathroomcaddy-black.jpg",
        silver: "./productphotos/bathroomcaddy-silver.jpg"
      },
      title: "Shower Caddy",
      description: "2 Pack",
      price: {
        black: "22.99",
        silver: "22.99"
      },
      link: {
        black:
          "https://www.amazon.com/VOLPONE-Adhesive-Required-Organizer-Bathroom/dp/B08DQQ211M?ref_=ast_sto_dp",
        silver:
          "https://www.amazon.com/VOLPONE-Adhesive-Required-Organizer-Bathroom/dp/B08DQS5TCL?ref_=ast_sto_dp&th=1"
      },
      resize: "",
      uniqID: 5
    },
    {
      img: {
        black: "./productphotos/glassshelf-black-clear-1t.jpg",
        silver: "./productphotos/glassshelf-silver-clear-1t.jpg"
      },
      title: "Glass Shelf",
      description: "1 Tier Transparent Glass with Towel Bar",
      price: { black: "19.99", silver: "19.99" },
      link: {
        black:
          "https://www.amazon.com/Bathroom-Bar-VOLPONE-Rustproof-Mounted-Storage/dp/B087RKH3BD?ref_=ast_sto_dp&th=1",
        silver:
          "https://www.amazon.com/Bathroom-Bar-VOLPONE-Rustproof-Mounted-Storage/dp/B087RKBPW2?ref_=ast_sto_dp&th=1"
      },
      resize: "",
      uniqID: 6
    },
    {
      img: {
        black: "./productphotos/glassshelf-black-clear-2t.jpg",
        silver: "./productphotos/glassshelf-silver-clear-2t.jpg"
      },
      title: "Glass Shelf",
      description: "2 Tier Transparent Glass with Towel Bar",
      price: { black: "39.99", silver: "39.99" },
      link: {
        black:
          "https://www.amazon.com/Bathroom-Bar-VOLPONE-Rustproof-Mounted-Storage/dp/B087RK33SG?ref_=ast_sto_dp&th=1",
        silver:
          "https://www.amazon.com/Bathroom-Bar-VOLPONE-Rustproof-Mounted-Storage/dp/B087RKNWWQ?ref_=ast_sto_dp&th=1"
      },
      resize: "",
      uniqID: 7
    },
    {
      img: "./productphotos/glassshelf-silver-clear-3t-notowelbar.jpg",
      title: "Glass Shelf - Silver",
      description: "3 Tier Transparent Glass without Towel Bar",
      price: "39.99",
      link:
        "https://www.amazon.com/Bathroom-Bar-VOLPONE-Rustproof-Mounted-Storage/dp/B08CGQLZY5?ref_=ast_sto_dp&th=1",
      resize: "",
      uniqID: 8
    },
    {
      img: "./productphotos/glassshelf-silver-frosted-1t.jpg",
      title: "Glass Shelf - Silver",
      description: "1 Tier Frosted Glass with Towel Bar",
      price: "23.99",
      link:
        "https://www.amazon.com/Bathroom-Bar-VOLPONE-Rustproof-Mounted-Storage/dp/B08CGPG37T?ref_=ast_sto_dp&th=1",
      resize: "",
      uniqID: 9
    },
    {
      img: {
        black: "./productphotos/handtowel-black-8inch.jpg",
        silver: "./productphotos/handtowel-silver-8inch.jpg"
      },
      title: "Wide Towel Bar",
      description: "8 Inch",
      price: { black: "13.99", silver: "12.99" },
      link: {
        black: "https://www.amazon.com/dp/B08BG37HXR/ref=twister_B08BG2S4RW",
        silver:
          "https://www.amazon.com/dp/B08BG1HT7Q/ref=twister_B08BG2S4RW?th=1"
      },
      resize: "",
      uniqID: 10
    },
    {
      img: "./productphotos/settowelbar.jpg",
      title: "Towel Bar - Silver",
      description: "2 Pack- 9 Inch with Hooks",
      price: "16.99",
      link: "https://www.amazon.com/dp/B08BG2BSRG/ref=twister_B08BG2S4RW?th=1",
      resize: "",
      uniqID: 11
    },

    {
      img: "./productphotos/handtowel-silver-16inch.jpg",
      title: "Towel Bar - Silver",
      description: "16 Inch with Hooks",
      price: "14.99",
      link: "https://www.amazon.com/dp/B08BG2P1PD/ref=twister_B08BG2S4RW?th=1",
      resize: "",
      uniqID: 12
    },
    {
      img: "./productphotos/towelbar-silver-24in.jpg",
      title: "Towel Bar - Silver",
      description: "24 Inch with Hooks",
      price: "19.99",
      link: "https://www.amazon.com/dp/B08BG21M2Q/ref=twister_B08BG2S4RW?th=1",
      resize: "",
      uniqID: 13
    },
    {
      img: {
        black: "./productphotos/towelbar-6pc-black.jpg",
        silver: "./productphotos/towelbar-6pc-silver.jpg"
      },
      title: "Towel Bar Set",
      description: "6 Piece Set",
      price: { black: "29.99", silver: "29.99" },
      link: {
        black:
          "https://www.amazon.com/VOLPONE-Bathroom-Hardware-Stainless-Toothbrush/dp/B088D8V1WX?ref_=ast_sto_dp",
        silver:
          "https://www.amazon.com/VOLPONE-Bathroom-Hardware-Stainless-Toothbrush/dp/B088D7V91C?ref_=ast_sto_dp&th=1"
      },
      resize: "contain",
      uniqID: 14
    },
    {
      img: "./productphotos/towelbar-black-v2.jpg",
      title: "Towel Rack - Black",
      description: "Dual Towel Bar Rack",
      price: "51.99",
      link:
        "https://www.amazon.com/VOLPONE-Bathroom-Mounted-Stainless-Brushed/dp/B08DNQBG15?ref_=ast_sto_dp",
      resize: "",
      uniqID: 15
    },
  ];
  let other = [
    // {
    //   img: "./productphotos/cart-3t-black.jpg",
    //   title: "Folding Shelf Cart - 3 Tier",
    //   description: "Pre-assembled Storage Rack",
    //   price: "79.99",
    //   link:
    //     "https://www.amazon.com/VOLPONE-Storage-Shelves-Freestanding-Assembly/dp/B08B1NZCX2?ref_=ast_sto_dp&th=1",
    //   resize: "",
    //   uniqID: 16
    // },
    {
      img: {
        black: "./productphotos/cart-4t-black.jpg",
        white: "./productphotos/cart-4t-white.jpg",
      },
      title: "Folding Shelf Cart - 4 Tier",
      description: "Pre-assembled Multifunctional Rack",
      price: { 
        black: "89.99", 
        white: "91.99" 
      },
      link: {
        black:
          "https://www.amazon.com/VOLPONE-Storage-Shelves-Freestanding-Assembly/dp/B08B1M7JRK?ref_=ast_sto_dp&th=1",
        white:
          "https://www.amazon.com/VOLPONE-Storage-Shelves-Freestanding-Assembly/dp/B08B1NKNRD?ref_=ast_sto_dp&th=1"
      },
      resize: "contain",
      uniqID: 28
    },
  ];
  let all = kitchen.concat(other).concat(bathroom);
  let timeZone = momentTz()
    .tz("America/Los_Angeles")
    .format("z");

  return (
    <>
      <div className="products">
        <div className="product-nav-container">
          <ul className="product-nav">
            <li
              name="all"
              onClick={changeTab}
              className={show === "all" ? "product-nav-selected" : null}
            >
              All
            </li>
            <li
              name="kitchen"
              onClick={changeTab}
              className={show === "kitchen" ? "product-nav-selected" : null}
            >
              Kitchen
            </li>
            <li
              name="bathroom"
              onClick={changeTab}
              className={show === "bathroom" ? "product-nav-selected" : null}
            >
              Bathroom
            </li>
            <li
              name="other"
              onClick={changeTab}
              className={show === "other" ? "product-nav-selected" : null}
            >
              Other
            </li>
          </ul>
          <div className="shipping">
            FREE 2 day shipping with{" "}
            <img
              src="./productphotos/primelogo.png"
              alt=""
              className="prime-logo"
            />
          </div>
        </div>

        <div className="product-page">
          <div className="time-stamp">
            Last Updated: May 28, 2021 03:15 pm PST
            {/* {moment().format("MMM D, YYYY, hh:HH a ")}
            {timeZone} */}
          </div>
          {show === "all" ? (
            all.map((item, i) => {
              return (
                <Card
                  img={item.img}
                  title={item.title}
                  description={item.description}
                  price={item.price}
                  link={item.link}
                  key={item.uniqID}
                />
              );
            })
          ) : show === "kitchen" ? (
            kitchen.map((item, i) => {
              return (
                <Card
                  img={item.img}
                  title={item.title}
                  description={item.description}
                  price={item.price}
                  link={item.link}
                  key={item.uniqID}
                />
              );
            })
          ) : show === "bathroom" ? (
            bathroom.map((item, i) => {
              return (
                <Card
                  img={item.img}
                  title={item.title}
                  description={item.description}
                  price={item.price}
                  link={item.link}
                  key={item.uniqID}
                />
              );
            })
          ) : show === "other" ? (
            other.map((item, i) => {
              return (
                <Card
                  img={item.img}
                  title={item.title}
                  description={item.description}
                  price={item.price}
                  link={item.link}
                  key={item.uniqID}
                />
              );
            })
          ) : (
            <div className="nothing">Nothing returned</div>
          )}
          <div className="images-note">
            * The product images shown are for illustration purposes only and
            may not be an exact representation of the product.
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemsPage;
