import react, { useState } from "react";
import "../styles/Slider.scss";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

function Slider({ imgs, title, imgList }) {
  let sliderArr = imgs.map((src, idx) => {
    return <img src={src} alt="slide-img" className="wall-img" key={idx}></img>;
  });
  const [x, setX] = useState(0);
  const goLeft = () => {
    if (x === 0) {
      setX(-100 * (sliderArr.length - 1));
    } else {
      setX(x + 100);
    }
  };
  const goRight = () => {
    if (x === -100 * (sliderArr.length - 1)) {
      setX(0);
    } else {
      setX(x - 100);
    }
  };

  const jumpToPic = idx => {
    setX(-100 * idx);
  };

  return (
    <>
      <div className="suitable-container">
        <div className="wrap">
          <h1>{title}</h1>
          {/* <div className="backdrop"></div> */}
          <div className="slider-container">
            {sliderArr.map((img, index) => {
              return (
                <div
                  key={index}
                  className="slide"
                  style={{ transform: `translateX(${x}%)` }}
                >
                  {img}
                </div>
              );
            })}
            <button id="goLeft" onClick={goLeft}>
              <BsChevronLeft size={25} />
            </button>
            <button id="goRight" onClick={goRight}>
              <BsChevronRight size={25} />
            </button>
          </div>
        </div>
        <ul className="image-list">
          {imgList.map((name, idx) => {
            return (
              <li key={idx}>
                <div
                  title={name}
                  style={{
                    background:
                      x === -idx * 100 ? "rgba(0, 0, 0, 0.06)" : "none"
                  }}
                  onClick={() => jumpToPic(idx)}
                >
                  {name}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default Slider;
