import React, { useEffect, useState, useRef } from "react";
import "../styles/Faq.css";
import Accordion from "./Accordion";
import Footer from "./Footer";
import { GoPackage } from "react-icons/go";
import { BsStarFill, BsStarHalf, BsPlusSquare } from "react-icons/bs";
import { HiChatAlt } from "react-icons/hi";
import { FaShippingFast } from "react-icons/fa";
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io"
import { useInView } from 'react-intersection-observer';
import adhesiveVideo from './adhesive-mounting.mp4';
import sinkCaddyVideo from './sinkcaddy.mp4';
import bathroomShelfVideo from './BathroomShelf.mp4';
import cornerGlassVideo from './cornerGlass.mp4';

function Faq() {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let toggleDetails = () => {
    setHidden(!hidden);
  };

  const [boxViewRef, boxInView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });

  let faqData = [
    {
      category: "Installation",
      faqList: [
        {
          question: "How reliable is the glue for installation?",
          answer:
            "The adhesive provided is high-strength and water and weather-resistant, its specifically formulated to bond our products with typical home walls indoor and outdoor. Extensive weight bearing tests were conducted to ensure accurate results, please refer to the specifications for your product. Keep in mind to follow the instructions carefully. Surface must be clean and dry before the glue is applied, and sufficient drying time is needed. You can rest assure it will hold weight up to the weight capacity."
        },
        {
          question: "Will the corner caddy fit on curved corners?",
          answer:
            "No, the design of the corner shower caddy only allows for right angle(90\xB0) corners. The rounded corners create too much distance between the wall and the shelf, neither the glue nor screws can be applied. "
        },
        {
          question: "Why is my shelf falling off my drywall?",
          answer:
            "Chances are you’re going over the given weight capacity or you might have missed a step during the installation process. The screws and anchors provided should be enough for typical use but placing more stress on the screws will require you to mount on the studs behind the wall. Locate the studs with a stud finder device or you can install an app on your smart phone. Drill the pilot holes at the center of the stud and fasten the screws as you normally would. Please note that anchors are not used when screwing into wood. If you need further help don’t hesitate to contact us at any time."
        },
        {
          question:
            "How do I detach from the wall? I live in an apartment and eventually I will have to move out.",
          answer:
            "Unmounting any of our products is quite simple. Screws can be easily removed, although patching the holes may be a hassle. Unmounting adhesive installed products are as follows: \n1) Carefully use a chisel or knife to separate it from the wall. \n2) Heat up the residual adhesive with a hair dryer. \n3) Remove excess glue from the wall. \n *Be cautious not to injure yourself during this process."
        },
        {
          question: "Is my wall suitable for adhesive mounting?",
          answer:
            "We know everyone’s situation may be different and various factors can come into play when trying to mount shelves in your home. Adhesive mounting is best on ceramic tiles, glass, marble, wood, and metal walls. If you are still unsure whether your wall can use our glue, feel free to contact us directly. For us to help, please provide photos and as much information as you can in our contact form."
        }
      ]
    },
    {
      category: "Product",
      faqList: [
        {
          question: "What can I do if I receive wrong or missing item?",
          answer:
            "Although its very unlikely this will happen, but in those extreme cases we suggest you schedule for an exchange with Amazon. If you are missing an instruction manual, please contact us and we will send you a digital copy. Even for situations like these we’d also like to ask our customers to notify us of any problems."
        },
        {
          question: "What is space aluminum?",
          answer:
            "Although the name is not official it is commonly used in our industry to describe the type of aluminum alloy that is also used in aerospace applications. The space grade aluminum alloy used are in the 6000 series, it combines relatively high strength, flexibility, and high resistance to corrosion. It also allows multiple coatings for a more advanced surface. Unlike other materials, this is lightweight yet strong and long-lasting. We value and take pride in the quality of our products and the materials used in manufacturing."
        },
        {
          question: "Will you have more products in the future?",
          answer:
            "Most definitely. Volpone is continually trying to improve existing products or creating new ones for the future. New inventions, ideas, and style are always changing and so are we. We do our best to offer affordable and quality home goods to everyone. If you have any suggestions or something you would like to see, please let us know. You can always look forward to seeing new products in our store."
        },
        {
          question: "Is it reusable after unmounting?",
          answer:
            "YES if you installed using screws. NO if you installed using adhesive. The adhesion will no longer be effective, and you will need to purchase an alternative adhesive. We currently do not sell the glue separately, but we do have plans in the future to do so."
        }
      ]
    },
    {
      category: "Returns",
      faqList: [
        {
          question: "What is your return policy?",
          answer:
            "Returns and exchanges are handled by Amazon. Like most items, Amazon offer returns within 30 days of receipt of shipment. For more information please visit "
        }
      ]
    }
  ];

  return (
    <>
      <div className="image-gallery">
        <div className="img-1"></div>
        <div className="img-2"></div>
        <div className="img-3"></div>
        <div className="img-4"></div>
        <div className="img-5"></div>
        <div className="faq-title-wrap">
          <div className="text">Frequently Asked Questions</div>
        </div>
      </div>

      <div className="volpone-support">
        <img
          src="./images/logotransparent.png"
          alt=""
          className="volpone-logo"
        />
        <div className="support">support</div>
      </div>

      <div className="info-section">
        <div className="info-container">
          <div className="info-img">
            <img src="./images/office.jpeg" alt="" />
          </div>
          <div className="info-description">
            <h1 className="info-title">About Us</h1>
            <p className="info-text">
              Volpone is driven to bring comfort to every home. We focus on
              instilling innovative and stylish designs into household items,
              primarly for space-saving and organization. Products are
              engineered to deliver the best experience in home living.
              Volpone's inspiration comes from different styles around the
              world, and we incorporate those ideas into our home goods. These
              storage solutions are designed to be functional as are they
              attractive in any home. We hope to make the daily life more
              enjoyable while helping build the dream home.
            </p>
            <div className="signature">
              Volpone <div className="location">Oakland, California</div>
            </div>
          </div>
        </div>
      </div>
    
      
      <div className="core-values-container">
        <h5>FOCUS ON WHAT MATTERS</h5>
        <h3>Our core values create long-lasting relationships</h3>
        <p>
          We are also customers of our own products so we understand what's to
          be expected. Everything Volpone stands for is to make your dream home.
        </p>
      </div>
      <div className="services" ref={boxViewRef}>
        <div className="service-box delivery" id={boxInView ? "reveal-outter-l" : "hide-outter-l"}>
          <FaShippingFast className="service-icon" />
          <div className="text-wrap">
            <h1>Delivery</h1>
            <h2>Free shipping with prime</h2>
            <p>Amazon prime offers free 2 day shipping for members</p>
          </div>
        </div>
        <div className="service-box ratings" id={boxInView ? "reveal-inner-l" : "hide-inner-l"}>
          <div className="text-wrap">
            <div className="service-icon stars">
              <BsStarFill className="star-icon" />
              <BsStarFill className="star-icon" />
              <BsStarFill className="star-icon" />
              <BsStarFill className="star-icon" />
              <BsStarHalf className="star-icon" />
            </div>
            <h1>Highly Rated</h1>
            <h2>Over 4.5 product ratings</h2>
            <p>See our product reviews and customer experiences</p>
          </div>
        </div>
        <div className="service-box support" id={boxInView ? "reveal-inner-r" : "hide-inner-r"}>
          <HiChatAlt className="service-icon" />
          <div className="text-wrap">
            <h1>Support</h1>
            <h2>We are here to help</h2>
            <p>Visit the 'Contact Us' page for ways of reaching us</p>
          </div>
        </div>
        <div className="service-box returns" id={boxInView ? "reveal-outter-r" : "hide-outter-r"}>
          <GoPackage className="service-icon" />
          <div className="text-wrap">
            <h1>Returns</h1>
            <h2>Free 30 day return</h2>
            <p>
              Returns and exchanges are always welcomed, use Amazon's services
              for more details
            </p>
          </div>
        </div>
      </div>
      <div className="product-quality-container">
        <div className="product-quality-wrapper"></div>
        <div className="product-quality-description">
          Advance Technology
          <h2 className="more-details">
            <span onClick={toggleDetails}>
              {hidden ? <IoIosArrowDropdownCircle/> : <IoIosArrowDropupCircle/>}
              Details
            </span>
          </h2>
          <div className="product-quality-wrap">
            <div
              className={
                hidden ? "hidden product-quality-info" : "product-quality-info"
              }
            >
              Volpone is always developing ways to improve the engineering and
              design of each product. New ideas are always underway, and every
              prototype is heavily tested for the best results. Maintaining
              product quality is also our utmost priority. We value and take
              pride in all of our products.
            </div>
          </div>
        </div>
      </div>
      <div className="faq-section">
        {faqData.map((group, idx) => {
          return (
            <div className="faq-container" key={idx}>
              <div className="category">{group.category}</div>
              {group.faqList.map((item, index) => {
                return (
                  <Accordion
                    question={item.question}
                    answer={item.answer}
                    key={index}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      <Footer />
    </>
  );
}

export default Faq;
