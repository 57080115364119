import React, { useState, useEffect } from "react";
import "../styles/Card.css";

function Card({ img, title, description, price, link }) {
  let tmpImg;
  let tmpColors = [];
  let tmpColor = "";
  let tmpLink = "";
  let tmpPrice = "";

  if (typeof img === "string") {
    tmpImg = img;
    tmpLink = link;
    tmpPrice = price;
  } else {
    tmpColors = Object.keys(img);
    tmpColor = tmpColors[0];
    tmpImg = img[tmpColor];
    tmpLink = link[tmpColor];
    tmpPrice = price[tmpColor];
  }
  const [currImg, setCurrImg] = useState(tmpImg);
  const [colors, setColors] = useState(tmpColors);
  const [currColor, setCurrColor] = useState(tmpColor);
  const [buyLink, setBuyLink] = useState(tmpLink);
  const [buyPrice, setBuyPrice] = useState(tmpPrice);

  let changeColor = e => {
    let newColor = e.target.getAttribute("data-color");
    setCurrColor(newColor);
    setCurrImg(img[newColor]);
    setBuyLink(link[newColor]);
    setBuyPrice(price[newColor]);
  };

  return (
    <>
      <div className="card-container">
        <div className="image-container">
          <img className="product-image" src={currImg} />
          <div className="modal">
            <a className="buy-now" href={buyLink} target="_blank">
              Buy
            </a>
          </div>
        </div>
        <div className="caption">
          {colors.length ? (
            <div className="color-option">
              Colors: &nbsp;
              {colors.map((aColor, i) => {
                return (
                  <div
                    className="color-frame"
                    data-color={aColor}
                    onClick={changeColor}
                  >
                    <div
                      className={`color-circle ${aColor}`}
                      data-color={aColor}
                      onClick={changeColor}
                      key={i}
                    ></div>
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className="caption-text">
            <div className="caption-title">{title}</div>
            <div className="caption-desc">{description}</div>
          </div>
          <div className="price">${buyPrice}</div>
        </div>
      </div>
    </>
  );
}

export default Card;
