import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import { Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import "../styles/Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [scroll, setScroll] = useState(false);

  const handleClick = () => {
    setClick(!click);
    // setScroll(!scroll);
  };

  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const changeNav = e => {
    if (window.scrollY >= 95) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  window.addEventListener("scroll", changeNav);

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);
  const location = useLocation();
  let navStyle = "";
  if (location.pathname === "/") {
    navStyle = "navbar-light";
  } else if (location.pathname === "/products") {
    navStyle = "navbar-products";
  } else if (location.pathname === "/faq") {
    navStyle = "navbar-installation";
  } else {
    navStyle = "navbar-dark";
  }

  return (
    <>
      <nav
        className={
          scroll && click
            ? `navbar ${navStyle}`
            : scroll
            ? `navbar navbar-shrink ${navStyle}`
            : `navbar ${navStyle}`
        }
      >
        <div className="navbar-container">
          <Link
            to="/"
            className="navbar-logo"
            onClick={() => {
              closeMobileMenu();
            }}
          >
            <img
              className="logo"
              src={"/images/logotransparent.png"}
              alt="image not found"
            />
          </Link>
          <div className="navbar-left">
            <div className="menu-icon" onClick={handleClick}>
              {click ? (
                <FaTimes className="fas fa-times" />
              ) : (
                <FaBars className="fas fa-bars" />
              )}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-links"
                  onClick={() => {
                    closeMobileMenu();
                  }}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/products"
                  className="nav-links"
                  onClick={() => {
                    closeMobileMenu();
                  }}
                >
                  Products
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/faquestions"
                  className="nav-links"
                  onClick={() => {
                    closeMobileMenu();
                  }}
                >
                  FAQ
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/faq"
                  className="nav-links"
                  onClick={() => {
                    closeMobileMenu();
                  }}
                >
                  Installation
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  to="/promo"
                  className="nav-links"
                  onClick={() => {
                    closeMobileMenu();
                  }}
                >
                  Promo
                </Link>
              </li> */}

              <li>
                <Link
                  to="/contactus"
                  className="nav-links-mobile"
                  onClick={() => {
                    closeMobileMenu();
                  }}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
            {button && <Button buttonStyle="btn--outline">Contact Us</Button>}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
