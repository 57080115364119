import React, { useRef, useEffect } from "react";
import "../styles/Home.css";
import { TimelineMax, Power2 } from "gsap";
import { FaAmazon, FaFacebookF } from "react-icons/fa";
import { SiWechat, SiTwitter } from "react-icons/si";
import { TiSocialInstagram } from "react-icons/ti";
import { IoIosChatboxes } from "react-icons/io";
import { Link } from "react-router-dom";

function Home() {
  let hero = useRef(null);
  let slider = useRef(null);
  let headline = useRef(null);
  let button = useRef(null);
  let description = useRef(null);

  useEffect(() => {
    const t1 = new TimelineMax();
    t1.fromTo(
      hero,
      0.8,
      { height: "0%" },
      { height: "80%", ease: Power2.easeInOut }
    )
      .fromTo(
        hero,
        1,
        { width: "100%" },
        { width: "85%", ease: Power2.easeInOut }
      )
      .fromTo(
        slider,
        0.8,
        { x: "-100%" },
        { x: "0%", ease: Power2.easeInOut },
        "-=1.2"
      )
      .fromTo(headline, 0.8, { opacity: 0 }, { opacity: 1 })
      .fromTo(
        button,
        0.8,
        { opacity: 0 },
        { opacity: 1, ease: Power2.easeInOut },
        "-=1.2"
      )
      .fromTo(
        description,
        1.1,
        { x: "150%" },
        { x: "0%", ease: Power2.easeInOut },
        "-=1"
      );
  });

  return (
    <section className="home-section">
      <div className="hero" ref={el => (hero = el)}>
        <img src="./images/background.jpg" alt="" />
        <h1 className="headline" ref={el => (headline = el)}>
          Comfort to your home
        </h1>
        <p className="description" ref={el => (description = el)}>
          Volpone strives to create innovative and contemporary products for
          your dream home by offering designs from around the globe to meet
          anyones lifestyle.{" "}
        </p>
      </div>
      <a
        className="center-button"
        href="https://www.amazon.com/stores/VOLPONE/page/B5FA913A-6EC3-48F7-83C3-CF3A76A5E421?ref_=ast_bln"
        target="_blank"
        ref={el => (button = el)}
      >
        <div className="center-button-text">
          SHOP
          <FaAmazon className="amazon-icon" />
        </div>
        <span className="right-arrow">&#8594;</span>
      </a>
      <div className="slider" ref={el => (slider = el)}></div>

      <div className="connect">
        <a
          href="http://volpone-wechat.surge.sh/"
          target="_blank"
          className="wechat"
        >
          <SiWechat className="social-icon wechat" />
        </a>
        <a href="" className="facebook">
          <FaFacebookF className="social-icon facebook" />
        </a>
        <a href="" className="instagram">
          <TiSocialInstagram className="social-icon instagram" />
        </a>
        <a href="" className="twitter">
          <SiTwitter className="social-icon twitter" />
        </a>
        <a href="" className="live-chat">
          <Link to="/contactus" className='live-chat-link'>
            <IoIosChatboxes className="social-icon" />
            <span className="live-chat-text">
                  Live Chat
            </span>
          </Link>
        </a>
      </div>
    </section>
  );
}

export default Home;
