import React, { useState, useEffect, useRef } from "react";
import {RiSendPlaneFill} from 'react-icons/ri';
import "../styles/Dashboard.css";
import io from "socket.io-client";
import CallWindow from './CallWindow';
import useLocalStorage from "../hooks/useLocalStorage";
import firebase from '../Firebase';
import { RiDeleteBin5Line } from "react-icons/ri";
import { BiChevronsDown } from "react-icons/bi";
import moment from 'moment';
const ENDPOINT = "https://volpone.herokuapp.com";



const Dashboard = (props) => {
  const [calling, setCalling] = useLocalStorage('calling', []);
  const [missed, setMissed] = useLocalStorage('missed', []);
  const [conversations, setConversations] = useLocalStorage('conversations', []);
  const [selectedPerson, setSelectedPerson] = useLocalStorage('selectedPerson', null);
  const [pageActive, setPageActive] = useState(false);
  const [message, setMessage] = useState('');
  const [showTips, setShowTips] = useState(false);
  const chatBoxRef = useRef(null)

  let socket = io(ENDPOINT, {transports: ['websocket', 'polling', 'flashsocket']});

  const missedCallers = firebase.firestore().collection("MissedCalls");
  // const convos = firebase.firestore().collection("Conversations");

  useEffect(() => {
    socket.on("call", (person) => {
      setCalling([...calling, person])
    })

    socket.on('addMissedCall', (person) => {
      setMissed([...missed, person])
    })

    getMissedCalls();

    socket.on('agent-recieve-message', (msg) => {
      setConversations((prevState) => {
        // prevState.forEach((customer) => {
        //   if(customer.socketID === msg.socketID){
        //     customer.messages.push(msg)
        //   }
        // })
        let tmp = [...prevState];
        tmp = tmp.map(customer => {
          if(customer.socketID === msg.socketID){
            customer.messages.push(msg);
          }
          return customer
        })
        return tmp
      })
      
    })
  }, []);

  useEffect(() => {
    socket.on('disconnectChat', (socketID) => {
      setConversations((prevState) => {
        return prevState.map(customer => {
          if(customer.socketID == socketID){
            customer.connected = false;
          }
          return customer;
        })
      });
      })
  }, [])


  useEffect(() => {
    chatBoxRef.current.scrollIntoView();
  }, [selectedPerson]);

  useEffect(() => {
    chatBoxRef.current.scrollIntoView({behavior: 'smooth'});
  }, [conversations]);

  let activatePage = () => {
    setPageActive(true);
  }

  let getMissedCalls = () => {
    missedCallers.orderBy("timestamp", "desc").onSnapshot((querySnapshot) => {
      let items = [];
      querySnapshot.forEach(person => {
        let tmp = person.data();
        items.push(tmp);
      })

      let combined = items.concat(missed) // merge two arrays
      let foo = new Map();
      for(const tag of combined) {
        foo.set(tag.socketID, tag);
      }
      setMissed([...foo.values()])
    })
  }

  // let getConversations = async () => {
  //   convos.orderBy("time", "desc").onSnapshot((querySnapshot) => {
  //     const items = [];
  //     querySnapshot.forEach(person => {
  //       items.push(person.data());
  //     })
  //     setConversations(items);
  //   })
  // }

  let acceptCallWindow = (person) => {
    let filtered = calling.filter((customer) => 
      person.socketID !== customer.socketID
    )
    setCalling(filtered);
    socket.emit('accept', person);
    choosePerson(person);
    person.connected = true;
    person.messages = [];
    let tmp = [...conversations, person]
    setConversations(tmp)
  }

  let cancelCallWindow = (person) => {
    let filtered = calling.filter((customer) => { 
    return person.socketID !== customer.socketID
    })
    setCalling(filtered);
    socket.emit('cancelCall', person);
  }

  let choosePerson = (person) => {
    const {name, email, socketID} = person
    const tmp = {name, email, socketID};
    setSelectedPerson(person);
  }

  // let showConversation = () => {
  //   // let docRef = convos.doc(selectedPerson.socketID)
  //   // docRef.get().then((doc) => {
  //   //     doc.ref.collection("messages").onSnapshot((querySnapshot) => {
  //   //       let items = [];
  //   //       querySnapshot.forEach(person => {
  //   //         items.push(person.data());
  //   //       })
  //   //       setSelected(items);
  //   //     })
  //   // })
  //   if(selectedPerson === null) return;
  //   let tmp = conversations.find((customer) => {
  //     return customer.socketID === selectedPerson.socketID;
  //   })
  //   if(tmp) setSelected(tmp.messages);
  // }

  let chatLanding = () => {
    setSelectedPerson(null);
  }

  let handleChange = (e) => {
    setMessage(e.target.value);
  }

  let submitMsg = (e) => {
    e.preventDefault();
    // ChatHelper.sendMsg(selectedPerson, true, message);
    let msg = { sender: true, text: message, time: moment().format('MM-DD-YY HH:mm'), socketID: selectedPerson.socketID, server: false }
    socket.emit('agent-send-message', msg);
    setConversations((prevState) => {
      let tmp = [...prevState];
      tmp = tmp.map(customer => {
        if(customer.socketID === msg.socketID){
          customer.messages.push(msg);
        }
        return customer
      })
      return tmp
    })
    setMessage('');
  }

  let disconnectCustomer = (person) => {
    // let tmp = conversations.map((customer) => {
    //   if(customer.socketID == person.socketID){
    //     customer.connected = false;
    //   }
    //   return customer
    // })
    // setConversations(tmp);
  }

  let deleteConvo = (person) => {
    console.log('the person in deleteConvo:  ', person)
    setConversations((prevState) => {
      let tmp = [...prevState];
      return tmp.filter((curr) => {
        return curr.socketID !== person.socketID;
      })
    })
  }

  let deleteMissedCall = (person) => {
    setMissed((prevState) => {
      let tmp = [...prevState];
      return tmp.filter((curr) => {
        return curr.socketID !== person.socketID;
      })
    })

    var missedCalls_query = missedCallers.where('socketID', '==', person.socketID);
    missedCalls_query.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        doc.ref.delete()
      })
    })


  }

  let hideTips = () => {
    setShowTips(!showTips);
  }

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-left">
          <div className="connected"><h1 onClick={chatLanding}>Conversations</h1>
          {
            conversations.length > 0
            ? conversations.map((person, i) => 
              <div key={i} className={person.connected ? "person" : "person disconnected"}
              id={
                (() => {
                  if(selectedPerson){
                    if(person.socketID === selectedPerson.socketID){
                      return "selected"
                      }
                    } 
                })()
              }
              onClick={() => {choosePerson(person)}}>
                <div className="person-name">Name:&ensp;{person.name}</div>
                <div className="person-email">Email:&ensp;{person.email}</div>
                <RiDeleteBin5Line className="delete-bin" onClick={() => {
                    if (window.confirm("Delete chat?")) {
                      deleteConvo(person)
                    }
                  } } />
              </div>)
            :
            null
          }
          </div>
          <div className="missed"><h1>Missed Calls</h1>
          {
            missed.length > 0 
            ? missed.map((person, i)=> 
              <div key={i} className="person">
                <div className="person-name">Name:&ensp;{person.name}</div>
                <div className="person-email">Email:&ensp;{person.email}</div>
                {
                  person.timestamp
                  ?
                  <div className="missed-date">{person.timestamp}</div>
                  :
                  <div className="no-time"></div>
                }
                <RiDeleteBin5Line className="delete-bin" onClick={() => {
                    if (window.confirm("Delete Missed Call?")) {
                      deleteMissedCall(person)
                    }
                  } } /> 
              </div>)
            :
            null
          }
          </div>
        </div>
        <div className="dashboard-right">
          {
            selectedPerson === null
            ?
            <div ref={chatBoxRef} className="no-selection">
              <img src="./images/naruto.gif" alt="Be patient..." />
              <div>No Conversations Selected</div> 
            </div>
            :
            <div className="dashboard-chat-container">
              <div className="chat-header">{selectedPerson.name}
              {
                selectedPerson.connected
                ?
                <div className="is-online"> Online </div>
                :
                <div className="is-offline"> Offline </div>
              }
              </div>
              <div className="dashboard-chat-body">
              <div className="system-msg">
                <div className="light">{selectedPerson.name} has entered the room.</div>
              </div>
                {
                selectedPerson.messages.map((msg, i) => {
                  return (
                    <div className={msg.sender ? "msg-container sender" : "msg-container recipient"}>
                      <div key={i} className="msg-text"
                        id={msg.server ? "server-msg" : ""}>
                        {msg.text}
                      </div>
                      {
                        msg.sender 
                        ?
                      <div className="msg-info">
                        <span>{msg.time}</span> <span>Volpone</span>
                      </div>
                      :
                      <div className="msg-info">
                        <span>{msg.time}</span> <span>{selectedPerson.name}&nbsp;&nbsp;</span>
                      </div>
                      }
                    </div>
                  )
                })
                }
                <div ref={chatBoxRef} className="dummy-div"></div>
              </div>
            </div>
          }
          <form className="chat-input" onSubmit={submitMsg}>
            <img src="./images/totoro.png" alt=""/>
            <input disabled={selectedPerson === null} type="text" onChange={handleChange} value={message} />
            <button type="submit" disabled={message.length < 1} className="send-message"><RiSendPlaneFill /></button>
        </form>
        </div>
        <div className="side-tools">
          <div className="tips" onClick={hideTips}>Tips <BiChevronsDown/> </div>
          <div className={showTips ? "tip-one" : "tip-one-hidden"}>
            <b>欢迎客戶: </b>
            <br/> Welcome! Thank you for contacting us. I am &lt;名字&gt;, your support representative. How may I assist you today?
            <br/><br/><b>不太清楚:</b> 
            <br/>Sorry, I dont quite understand. Can you please clarify by providing more details.
            <br/><br/>
            <b>再见说话: </b>
            <br/> 
            1) 问: Is there anything else I can help you with?
            <br/>
            2) 如果回没有:
            We hope all your issues were resolved to your satisfaction. Should you have any further questions please don't hesitate to contact us again. Thank you for choosing Volpone. Have a great day, Goodbye!
          </div>
          <img src="./images/luffysupport.png" id="luffy" alt=""/>
        </div>
      </div>
      {
        calling.length > 0
        ? 
        calling.map((caller, i) => 
          <CallWindow key={i} 
          person={caller} 
          acceptCallWindow={acceptCallWindow}
          cancelCallWindow={cancelCallWindow}/>
        )
        :
        null
      }
      {
        pageActive
        ?
        null
        :
        <div className="page-active">
          笑一笑世界更美妙
          <button onClick={activatePage}>&lt; Start &gt;</button>
        </div>
      }   
    </>
  );
};

export default Dashboard;
