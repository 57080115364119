import React, { useState, useRef, useEffect, useCallback } from "react";
import "../styles/Installation.css";
import LocomotiveScroll from 'locomotive-scroll';
import '../../node_modules/locomotive-scroll/src/locomotive-scroll.scss';
import { VideoModal } from './VideoModal';
// import useOnScreen from '../hooks/useOnScreen';
import { useInView } from 'react-intersection-observer';
import Slider from "./Slider";
import { Link, useLocation } from "react-router-dom";
import ReactDOM from 'react-dom';
import Footer from "./Footer";

function Installation() {
  const [showModal, setShowModal] = useState(false);
  const [vid, setVid] = useState('');
  let openModal = (video) => {
    setShowModal(prev => !prev);
    setVid(video);
  }

  var scroll = null;

  const ref = useRef();
  const installRef = useRef();

  const [boxViewRef1, boxInView1] = useInView({
    threshold: 0.5,
    triggerOnce: window.innerWidth <= 1024 ? true : false
  });

  const [boxViewRef2, boxInView2] = useInView({
    threshold: 0.5,
    triggerOnce: window.innerWidth <= 1024 ? true : false
  });
  const [boxViewRef3, boxInView3] = useInView({
    threshold: 0.5,
    triggerOnce: window.innerWidth <= 1024 ? true : false
  });
  const [boxViewRef4, boxInView4] = useInView({
    threshold: 0.5,
    triggerOnce: window.innerWidth <= 1024 ? true : false
  });
  const [boxViewRef5, boxInView5] = useInView({
    threshold: 0.5,
    triggerOnce: window.innerWidth <= 1024 ? true : false
  });
  const [boxViewRef6, boxInView6] = useInView({
    threshold: 0.5,
    triggerOnce: window.innerWidth <= 1024 ? true : false
  });
  const [boxViewRef7, boxInView7] = useInView({
    threshold: 0.5,
    triggerOnce: window.innerWidth <= 1024 ? true : false
  });
  const [boxViewRef8, boxInView8] = useInView({
    threshold: 0.5,
    triggerOnce: window.innerWidth <= 1024 ? true : false
  });
  const [boxViewRef9, boxInView9] = useInView({
    threshold: 0.5,
    triggerOnce: window.innerWidth <= 1024 ? true : false
  });
  const [boxViewRef10, boxInView10] = useInView({
    threshold: 0.5,
    triggerOnce: window.innerWidth <= 1024 ? true : false
  });

  const[adhesiveCompRef1, adhesiveCompInView1] = useInView({
    threshold: 0.5,
    triggerOnce: true
  })

  const[adhesiveCompRef2, adhesiveCompInView2] = useInView({
    threshold: 0.5,
    triggerOnce: true
  })

  const [rightHalfViewRef, rightHalfInView] = useInView({
    // threshold: 0.1
  });

  const [adhesiveBoxViewRef, adhesiveBoxInView] = useInView({
    threshold: 0.5,
    triggerOnce: true
  });

  const [helpSectionViewRef, helpSectionInView] = useInView({
    threshold: 0.7,
    triggerOnce: true
  });


  const setRefs = useCallback(
    (node) => {
      rightHalfViewRef(node)
    },
    [rightHalfViewRef],
  )

  useEffect(() => {
    if(rightHalfInView) {
      document.querySelector(".navbar-installation").classList.add('navbar-shrink');
    } else {
      document.querySelector(".navbar-installation").classList.remove('navbar-shrink');
    }
  },[rightHalfInView]);

  const location = useLocation();
  useEffect(() => {
    // window.scrollTo(0, 0);
        if (location.hash === "#instruction") {
      let node = document.getElementById("instruction");
      node.scrollIntoView({block: 'center'});
    } else if (location.hash === "#sink-caddy") {
      let node = document.getElementById("sink-caddy");
      node.scrollIntoView({block: 'center'});
    } else if (location.hash === "#bathroom-shelf") {
      let node = document.getElementById("bathroom-shelf");
      node.scrollIntoView({block: 'center'});
    } else if (location.hash === "#corner-glass-shelf") {
      let node = document.getElementById("corner-glass-shelf");
      node.scrollIntoView({block: 'center'});
    } else if (location.hash === "#GSLV-AL-15IN-ST") {
      let node = document.getElementById("GSLV-AL-15IN-ST");
      node.scrollIntoView({block: 'center'});
    }
  
    if (window.innerWidth >= 1024) {
      if(installRef){
        scroll = new LocomotiveScroll({
          el: installRef.current,
          smooth: true,
          direction: "horizontal",
        })
      }
    }
    return () => {
      if(scroll) scroll.destroy();
    }
  }, []);

  let suitables = [
    "./wallpics/wood.jpg",
    "./wallpics/ceramictile.jpg",
    "./wallpics/marble.jpg",
    "./wallpics/metal.jpg",
    "./wallpics/glasswall.jpg"
  ];

  let suitableList = ["Wood", "Ceramic", "Marble", "Metal", "GlassWall"];

  let notSuitables = [
    "./wallpics/lacquered.jpg",
    "./wallpics/paintedwall.jpg",
    "./wallpics/wallpaper.jpg",
    "./wallpics/limedwall.jpg",
    "./wallpics/concrete wall.jpg"
  ];

  let notSuitableList = [
    "Lacquered",
    "Painted",
    "Wallpaper",
    "Limed",
    "Concrete"
  ];

  return (
    <>
      <VideoModal showModal={showModal} setShowModal={setShowModal} vid={vid}/>
     
        <div className="installation-container" data-scroll-container ref={installRef} >
          <div className="installation-content">
            <div className="installation-bg" 
              data-scroll
              data-scroll-speed="-10"
              data-scroll-position="left">
            </div>
            
            <div className="video-instructions">
              <div className="video-instruction-text">
                Video Instructions
                <div className="video-instruction-subtext">
                  Adhesive mounting installation
                </div>
              </div>
            </div>
                        
            <div className="right-half" ref={setRefs}>
            <div className="video-gallery">
              <div className="video-box-wrapper" id="instruction" onClick={()=>{openModal("instruction")}} ref={boxViewRef1}>
                <div className={boxInView1 ? "video-box reveal-vid" : "video-box hide-vid"}>
                  <img id="vid-thumbnail" src="./productphotos/cornercaddy-silver-upgrade.jpg" alt=""/>
                  <div className="product-name">General Install</div>
                  <div className="watch-text">Watch</div>
                  <span className="box-color box-color1"></span>
                </div>
              </div>
              <div className="video-box-wrapper" id="sink-caddy" onClick={()=>{openModal("sink-caddy")}} ref={boxViewRef2}>
              <div className={boxInView2 ? "video-box reveal-vid" : "video-box hide-vid"}>
                  <img id="vid-thumbnail" src="./productphotos/kitchen-sink-caddy.jpg" alt=""/>
                  <div className="product-name">Kitchen Sink Caddy</div>
                  <div className="watch-text">Watch</div>
                  <span className="box-color box-color2"></span>
                </div>
              </div>
              <div className="video-box-wrapper" id="bathroom-shelf" onClick={()=>{openModal("bathroom-shelf")}} ref={boxViewRef3}>
              <div className={boxInView3 ? "video-box reveal-vid" : "video-box hide-vid"}>
                  <img id="vid-thumbnail" src="./productphotos/bathroom-shelf.jpg" alt=""/>
                  <div className="product-name">Bathroom Shower Caddy</div>
                  <div className="watch-text">Watch</div>
                  <span className="box-color box-color3"></span>
                </div>
              </div>
              <div className="video-box-wrapper" id="corner-glass-shelf" onClick={()=>{openModal("corner-glass-shelf")}} ref={boxViewRef4}>
              <div className={boxInView4 ? "video-box reveal-vid" : "video-box hide-vid"}>
                  <img id="vid-thumbnail" src="./productphotos/cornercaddy-black.jpg" alt=""/>
                  <div className="product-name">Corner Glass Shelf</div>
                  <div className="watch-text">Watch</div>
                  <span className="box-color box-color4"></span>
                </div>
              </div>
              <div className="video-box-wrapper" id="GSLV-AL-15IN-ST" onClick={()=>{openModal("GSLV-AL-15IN-ST")}} ref={boxViewRef5}>
              <div className={boxInView5 ? "video-box reveal-vid" : "video-box hide-vid"}>
                  <img id="vid-thumbnail" src="./productphotos/glassshelf-silver-clear-1t.jpg" alt=""/>
                  <div className="product-name">Bathroom Glass Shelf</div>
                  <div className="watch-text">Watch</div>
                  <span className="box-color box-color5"></span>
                </div>
              </div>
              <div className="video-box-wrapper" id="vid6" onClick={()=>{openModal("corner-glass-shelf")}} ref={boxViewRef6}>
              <div className={boxInView6 ? "video-box reveal-vid" : "video-box hide-vid"}>
                  <img id="vid-thumbnail" src="./productphotos/bathroomcaddy-black.jpg" alt=""/>
                  <div className="product-name">Bathroom Shower Caddy</div>
                  <div className="watch-text">Watch</div>
                  <span className="box-color box-color6"></span>
                </div>
              </div>
              <div className="video-box-wrapper" id="vid7" onClick={()=>{openModal("corner-glass-shelf")}} ref={boxViewRef7}>
              <div className={boxInView7 ? "video-box reveal-vid" : "video-box hide-vid"}>
                <img id="vid-thumbnail" src="./productphotos/handtowel-silver-16inch.jpg" alt=""/>
                  <div className="product-name">Bathroom Shower Caddy</div>
                  <div className="watch-text">Watch</div>
                  <span className="box-color box-color7"></span>
                </div>
              </div>
              <div className="video-box-wrapper" id="vid8" onClick={()=>{openModal("corner-glass-shelf")}} ref={boxViewRef8}>
              <div className={boxInView8 ? "video-box reveal-vid" : "video-box hide-vid"}>
                  <img id="vid-thumbnail" src="./productphotos/spicerack.jpg" alt=""/>
                  <div className="product-name">Bathroom Shower Caddy</div>
                  <div className="watch-text">Watch</div>
                  <span className="box-color box-color8"></span>
                </div>
              </div>
              <div className="video-box-wrapper" id="vid9" onClick={()=>{openModal("corner-glass-shelf")}} ref={boxViewRef9}>
              <div className={boxInView9 ? "video-box reveal-vid" : "video-box hide-vid"}>
                  <img id="vid-thumbnail" src="./productphotos/organizer-shelf-black.jpg" alt=""/>
                  <div className="product-name">Bathroom Shower Caddy</div>
                  <div className="watch-text">Watch</div>
                  <span className="box-color box-color9"></span>
                </div>
              </div>
              <div className="video-box-wrapper" id="vid10" onClick={()=>{openModal("corner-glass-shelf")}} ref={boxViewRef10}>
              <div className={boxInView10 ? "video-box reveal-vid" : "video-box hide-vid"}>
                  <img id="vid-thumbnail" src="./productphotos/spicerack.jpg" alt=""/>
                  <div className="product-name">Bathroom Shower Caddy</div>
                  <div className="watch-text">Watch</div>
                  <span className="box-color box-color8"></span>
                </div>
              </div>
            </div>

              <div className="adhesive-container" id="adhesive-container-target">
                <div className="adhesive-glue-bg" data-scroll data-scroll-sticky data-scroll-target="#adhesive-container-target"></div>              
                <div className="adhesive-intro-page">
                  <div className="adhesive-box-wrap" ref={adhesiveBoxViewRef}>
                    <div className={adhesiveBoxInView ? "adhesive-title-box" : "adhesive-title-box hide"}>
                      <div className="adhesive-title">
                        <div className="adhesive-title-wrap">
                          Adhesive Installation
                        </div>
                      </div>
                      <div className="adhesive-intro">
                        Traditionally mounting with screws worked well on any wall, but it
                        wasnt always easy, especially without the proper tools. Volpone has
                        developed a glue designed for easy mounting/unmounting, and its also
                        a great alternative if you do not want to damage your wall. The following
                        lists the various types of surfaces suitable for our adhesive
                        glue. The general requirement is that the wall must be smooth and
                        solid. Common home interior drywalls is not recommended because it can strip away the outer layer.
                        Adhesive mounting may not work on all surfaces, but when it does you will come to appreciate it.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="adhesive-suitable-page"
                id={adhesiveCompInView1 ? 'show-slider' : 'hide-slider'}
                ref={adhesiveCompRef1}>
                  <Slider
                    imgs={suitables}
                    title={"Adhesive Compatible"}
                    imgList={suitableList}
                  />
                </div>

                <div className="adhesive-not-suitable-page"
                id={adhesiveCompInView2 ? 'show-slider' : 'hide-slider'}
                ref={adhesiveCompRef2}>
                  <Slider
                    imgs={notSuitables}
                    title={"NOT Adhesive Compatible"}
                    imgList={notSuitableList}
                  />
                </div>

              </div>

              <div className="help-section"
                // data-scroll
                // data-scroll-speed={-5}
                ref={helpSectionViewRef}
              >
                <div className="product-specs-container">
                  <div className="specs-box">
                    <div className="big-text">Rust Warranty</div>
                    <div className="small-text">10-year protection plan for corrosion is included
                    with many products. Coverage is applicable from normal wear and tear.
                    </div>
                  </div>
                  <div className="specs-box">
                    <div className="big-text">Accessories Included</div>
                    <div className="small-text">All necessary hardware and accessories for installation
                    will be provided. Any missing or damaged parts are eligible for replacement parts.
                    </div>
                  </div>
                  <div className="specs-box">
                    <div className="big-text">Two Installation Methods</div>
                    <div className="small-text">Most products allow drilling or adhesive mounting,
                    ensuring compatibility for any wall. See more details in product descriptions. 
                    </div>
                  </div>
                </div>

                <div className="help-title"
                  data-scroll
                  data-scroll-speed="10"
                  data-scroll-position="center"
                >
                  Need more help?
                  <a href="/contactus" className='message-us'>
                    <span className='message-us'>Message us</span>
                  </a>
                </div>
                {/* <div className="footer-pic-wrap"> */}
                  {/* <img src="./footer-daylight.svg" alt="" className={helpSectionInView ? "footer-pic reveal" : "footer-pic hide"}/> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
    </>
  );
}

export default Installation;
