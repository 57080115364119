import React, { useState, useEffect, useRef } from "react";

import { FaMinus, FaCaretRight } from "react-icons/fa";
import "../styles/Faq.css";

function Accordion({ question, answer }) {
  const [show, setShow] = useState(false);
  const contentRef = useRef(null);

  let toggleAnswer = () => {
    setShow(!show);
  };

  useEffect(() => {
    contentRef.current.style.maxHeight = show
      ? `${contentRef.current.scrollHeight}px`
      : "0px";
    // contentRef.current.style.backgroundColor = show ? "white" : "";
  }, [contentRef, show]);

  return (
    <>
      <div className={`accordion-container ${show ? "accordion-show" : ""}`}>
        <div
          className={`question ${show ? "show-question" : ""} `}
          onClick={toggleAnswer}
        >
          <div className="text">{question}</div>
          <div className="plus-minus">
            {show ? (
              <FaMinus className="faMinus" />
            ) : (
              <FaCaretRight className="faRight" />
            )}
          </div>
        </div>
        <div className={`answer`} ref={contentRef}>
          {question === "What is your return policy?" ? (
            <div className="answer-text">
              {answer}
              <a
                href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GKM69DUUYKQWKWX7"
                target="_blank"
                className="amazon-return-policy"
              >
                Amazon's returns policy.
              </a>
            </div>
          ) : (
            <div className="answer-text">{answer}</div>
          )}
        </div>
      </div>
    </>
  );
}

export default Accordion;
