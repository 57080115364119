import React, { useState, useRef, useEffect } from "react";
import { FaSync, FaPaperPlane, FaCloudUploadAlt } from "react-icons/fa";

import { AiFillAmazonCircle } from "react-icons/ai";
import { TiMail } from "react-icons/ti";

import { IoIosChatboxes } from "react-icons/io";

import Dropzone, { useDropzone } from "react-dropzone";
import JSZip from "jszip";
import Footer from "./Footer";
import Modal from "./Modal";
import "../styles/Contact.css";

const audio = new Audio();

function Contact() {
  const [file, setFile] = useState([]);
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [order, setOrder] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [bots, setBots] = useState(false);
  const [tooltip, setTooltip] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  let webContact = useRef(null);

  useEffect(() => {
    // window.scrollTo(0, 0);
  });


  const encode = data => {
    const formData = new FormData();
    Object.keys(data).forEach(k => {
      formData.append(k, data[k]);
    });
    return formData;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === "name") {
      return setName(value);
    }
    if (name === "email") {
      return setEmail(value);
    }
    if (name === "order") {
      return setOrder(value);
    }
    if (name === "subject") {
      return setSubject(value);
    }
    if (name === "message") {
      return setMessage(value);
    }
  };

  let handleDrop = files => {
    setFileNames(files);
    if (files.length === 1) {
      setFile(files[0]);
    } else {
      const zip = new JSZip();
      files.forEach(file => zip.file(file.name, file));
      zip.generateAsync({ type: "uint8array" }).then(data => {
        const zippedFiles = new File([data], "customer-photos.zip", {
          lastModified: Date.now(),
          type: "application/zip"
        });
        setFile(zippedFiles);
      });
    }
  };

  let resetFields = () => {
    setFile([]);
    setStatus("");
    setName("");
    setEmail("");
    setOrder("");
    setSubject("");
    setMessage("");
    setFileNames([]);
  };

  let handleSubmit = e => {
    e.preventDefault();
    if (bots) {
      setStatus("error");
      return;
    }
    resetFields();
    setStatus("loading");
    const data = {
      "form-name": "contact",
      name,
      email,
      order,
      subject,
      message,
      file
    };
    fetch("/", {
      method: "POST",
      body: encode(data)
    })
      .then(() => {
        setStatus("sent");
      })
      .catch(error => setStatus("error"));
  };

  let showTooltip = () => {
    setTooltip(true);
    setTimeout(() => {
      setTooltip(false);
    }, 1800);
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({ handleDrop });

  let checkBots = () => {
    setBots(true);
  };

  let jumpToContact = () => {
    webContact.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  let onClose = () => {
    setIsOpen(false);
  };

  let callVolpone = e => {
    e.preventDefault();
  };

  return (
    <>
      <div className="contact-container">
        <div className="top">
          <div className="title">
            <div className="title-wrap title1">Volpone</div>
            <div className="title-wrap title2"><span>&nbsp;Help Center</span></div>
          </div>
          <div className="sub-title"> Comfort at your home</div>
        </div>
        <div className="contact-description">
          <div className="contact-headline">
            We love hearing back from our customers, your feedback help us
            provide better service and quality products. Get in touch and we'll
            be sure to answer all your inquiries within 24 hours.
          </div>
          <div className="quick-link-title">Quick Links</div>
        </div>
        <div className="blank-space">
          <div className="quick-links">
            <div className="shortcut web" onClick={jumpToContact}>
              <img
                className="web-shortcut"
                src="./images/logotransparent.png"
                alt=""
              />
              <p>Contact us directly by submitting the form below.</p>
            </div>
            <div
              className="shortcut live-chat"
              onClick={e => {
                setIsOpen(true);
                callVolpone(e);
              }}
            >
              {" "}
              <div className="name">
                <IoIosChatboxes className="shortcut-icon chatbox" />{" "}
                <span>LIVE Chat</span>
              </div>
              <p>
                24/7 live chat available with a representative
              </p>
            </div>
            <div
              className="shortcut direct-email"
              onClick={(e) => {
                console.log('one two thrreeee click')
                e.preventDefault();
                navigator.clipboard.writeText("volpone.usa@gmail.com");
                showTooltip();
              }}
            >
              {" "}
              <div className="name">
                <TiMail className="shortcut-icon email" /> <span>Email</span>
              </div>
              <p>
                Send us an email at <u>Volpone.usa@gmail.com</u>
                <div className="copied-wrap">
                  <span
                    className={tooltip ? "tooltip" : "tooltip hide-tooltip"}
                  >
                    Copied
                  </span>
                </div>
              </p>
            </div>

            <div className="shortcut amazon">
              <a
                href="https://www.amazon.com/sp?_encoding=UTF8&asin=&isAmazonFulfilled=1&isCBA=&marketplaceID=ATVPDKIKX0DER&orderID=&protocol=current&seller=A2ERTA6S3AX8B0&sshmPath="
                target="_blank"
              >
                {" "}
                <div className="name">
                  <AiFillAmazonCircle className="shortcut-icon amazon" />{" "}
                  <span>Amazon</span>
                </div>
                <p>Go to our Amazon and click "Ask a question"</p>
              </a>
            </div>
          </div>
        </div>

        <div className="contact" ref={webContact}>
          <form action="/contact" onSubmit={handleSubmit}>
            <h2>
              <FaPaperPlane className="fa-paperplane" />
              &nbsp;Contact us
            </h2>
            <div className="inputBox">
              <input
                type="text"
                value={name}
                name="name"
                placeholder="enter name here"
                onChange={handleChange}
              />
              <span>Full Name</span>
            </div>
            <div className="inputBox">
              <input
                type="email"
                value={email}
                name="email"
                placeholder="enter name here"
                onChange={handleChange}
                required
              />
              <span>* Email Address</span>
            </div>
            <div className="inputBox">
              <input
                type="text"
                value={subject}
                name="subject"
                placeholder="enter name here"
                onChange={handleChange}
              />
              <span>Subject</span>
            </div>
            <div className="inputBox">
              <input
                type="text"
                value={order}
                name="order"
                placeholder="enter name here"
                onChange={handleChange}
              />
              <span>Order Number</span>
            </div>
            <div className="inputBox message">
              <textarea
                name="message"
                value={message}
                placeholder="enter name here"
                onChange={handleChange}
                required
              ></textarea>
              <span>* Your message here...</span>
            </div>
            <div className="inputBox fileup">
              <Dropzone onDrop={handleDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section className="drop-zone">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} name="files" />
                      <div for="file-upload" className="custom-file-upload">
                        <FaCloudUploadAlt /> Drop or Upload Photos/File
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>

              <div className="file-name-container">
                {fileNames.length === 0 ? (
                  <p className="file-name">No file chosen</p>
                ) : (
                  fileNames.map(obj => {
                    return <p className="file-name">{obj.name}&nbsp;</p>;
                  })
                )}
              </div>
            </div>

            <div className="guardian-protector" hidden>
              <label>
                Name: <input type="text" onChange={checkBots} />
              </label>
            </div>
            <div className="inputBox submit-btn">
              <div className="status-container">
                {status === "loading" ? (
                  <FaSync className="fa-spin" />
                ) : status === "sent" ? (
                  <div className="status sent">
                    Successfully submitted message!
                  </div>
                ) : status === "error" ? (
                  <div className="status error">
                    {" "}
                    Something went wrong. Try again.{" "}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <button type="submit">Send</button>
            </div>
          </form>
        </div>
        <Modal open={isOpen} onClose={onClose} />
        <Footer />
      </div>
    </>
  );
}

export default Contact;
