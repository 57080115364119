import React, { useState, useEffect } from "react";
import "../styles/Modal.css";
import ReactDom from "react-dom";
import ChatWindow from "./ChatWindow";
import io from "socket.io-client";
const ENDPOINT = "https://volpone.herokuapp.com";
let socket;

export default function Modal({ open, onClose }) {
  const [showChatWindow, setShowChatWindow] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const nameErrorRef = React.createRef();
  const emailErrorRef = React.createRef();

  useEffect(() => {
    socket = io(ENDPOINT, {transports: ['websocket', 'polling', 'flashsocket']});
    // return () => {
    //       socket.emit('endChat', { socketID: socket.id, name: name, email: email });
    //       socket.emit('disconnect', { socketID: socket.id, name: name, email: email });
    //       socket.off();
    //     }
  }, [ENDPOINT]);

  // useEffect(() => {
  //   return () => {
  //     socket.emit('endChat', { socketID: socket.id, name: name, email: email });
  //     socket.emit('disconnect', { socketID: socket.id, name: name, email: email });
  //     socket.off();
  //   }
  // })


  const handleNameChange = e => {
    setName(e.target.value);
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);
  }

  let closeChat = (person) => {
    onClose();
    setShowChatWindow(false);
    resetInput();
  };

  const formValidate = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validEmail = re.test(String(email).toLowerCase());
    let formValid = true;
    nameErrorRef.current.innerText = "";
    emailErrorRef.current.innerText = "";
    if (name === "") {
      setErrorFor(nameErrorRef, "Your name is required.");
      formValid = false;
    }
    if (email === "") {
      setErrorFor(emailErrorRef, "Your email is required.");
      formValid = false;
    } else if (!validEmail) {
      setErrorFor(emailErrorRef, "Invalid email entered.");
      formValid = false;
    }
    return formValid;
  };

  const setErrorFor = (ref, message) => {
    ref.current.innerText = message;
  };

  const startChat = () => {
    let isValid = formValidate();
    if (isValid) {
      let customerInfo = { socketID: socket.id, name: name, email: email}
      socket.emit("call", customerInfo);
      setShowChatWindow(true);
    }
  };

  const resetInput = () => {
    setName('');
    setEmail('');
  };


  if (!open) return null;
  return ReactDom.createPortal(
    <>
      <div className="overlay"></div>
      {showChatWindow ? (
        <ChatWindow closeChat={closeChat} 
        person={{ socketID: socket.id, name: name, email: email}} socket={socket} />
      ) : (
        <div className="moodal">
          <h1>Live Chat Support</h1>
          <div className="field customer-name">
            <label htmlFor="">*Name:</label>
            <input
              type="text"
              onChange={handleNameChange}
              name="name"
              placeholder="How do we greet you"
            />
          </div>
          <div className="field customer-email">
            <label htmlFor="">*Email:</label>
            <input
              type="text"
              onChange={handleEmailChange}
              name="email"
              placeholder="Your email"
            />
          </div>
          <div className="error-message">
            <div ref={nameErrorRef} className="error-name"></div>
            <div ref={emailErrorRef} className="error-email"></div>
          </div>
          <div className="text-wrap">
            <p>* Required fields</p>
            We apologize in advance if no agents are available. Please consider contacting
            us using other methods on our Contact Us page.
          </div>
          <div className="moodal-buttons">
            <button id="start" onClick={startChat}>
              Start
            </button>
            <button id="cancel" onClick={closeChat}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </>,
    document.getElementById("portal")
  );
}
