import React, { useEffect, useState, useRef } from "react";
import "../styles/Faq.css";
import adhesiveVideo from './adhesive-mounting.mp4';
import sinkCaddyVideo from './sinkcaddy.mp4';
import bathroomShelfVideo from './BathroomShelf.mp4';
import cornerGlassVideo from './cornerGlass.mp4';
import GSLVAL15INST from './GSLV-AL-15IN-ST.mp4';

export const VideoModal = ({ showModal, setShowModal, vid }) => {
  const modalRef = useRef();

  // const animation = useSpring({
  //   config: {
  //     duration: 350
  //   },
  //   opacity: showModal ? 1 : 0,
  //   transform: showModal ? `translateY(0%)` : `translateY(-100%)`
  // })

  const dictionary = {
    'corner-glass-shelf': cornerGlassVideo,
    'bathroom-shelf': bathroomShelfVideo,
    'sink-caddy': sinkCaddyVideo,
    'instruction': adhesiveVideo,
    'GSLV-AL-15IN-ST': GSLVAL15INST
  }

  const closeModal = e => {
    if(modalRef.current === e.target){
      setShowModal(false);
    }
  }

  return (
    <>
    {
      showModal ?
      <div className="video_modal" ref={modalRef} onClick={closeModal}>
        <div className='video-wrapper'>
          <video controls className="adhesive-video" src={dictionary[vid]}></video>
        </div>
      </div>
      :
      null
    }
    </>
  )
}
