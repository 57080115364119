import React, { useEffect } from 'react';
import audioFile from "./ring_song.mp3";
import "../styles/CallWindow.css";
import PuffLoader from "react-spinners/PuffLoader";
// import ChatHelper from "../ChatHelper";
const audio = new Audio();
var timerId;

export default function CallWindow({person, cancelCallWindow, acceptCallWindow, key}) {

  useEffect(() => {
    audio.src = audioFile;
    audio.autoplay = true;
    audio.play();
    var timer = () => setTimeout(()=> {
      audio.pause();
      cancelCallWindow(person);
    }, 60000)
    timerId = timer();
  }, []);

  let accept = () => {
    audio.pause();
    acceptCallWindow(person);
    clearTimeout(timerId);
  }

  return (
    <div className='call-window' style={{zIndex: key + 1}}>
      <div>{person.name} is calling !!</div>
        <PuffLoader color={"rgba(205, 45, 45)"}/>
      <button onClick={accept}>Accept</button>
    </div>
  )
}
