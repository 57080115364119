import React, { useRef, useEffect } from "react";
import Navbar from "./components/Navbar";
import "./styles/App.css";
import Home from "./components/Home";
import { BrowserRouter, Switch } from "react-router-dom";
import { Route, NavLink, HashRouter, Redirect } from "react-router-dom";
import Faq from "./components/Faq";
import Products from "./components/Products";
import Contact from "./components/Contact";
import Installation from "./components/Installation";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import CookiesPolicy from "./components/CookiesPolicy";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Promo from "./components/Promos";
import AuthService from "./AuthService";

function App(props) {
  useEffect(() => {
    localStorage.setItem("isChatting", false);
  }, []);
  const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        AuthService.loggedIn() === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );

  const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        AuthService.loggedIn() === true ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/products" component={Products} />
          <Route path="/faquestions" component={Faq} />
          <Route path="/faq" component={Installation} />
          <Route path="/contactus" component={Contact} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-and-conditions" component={TermsConditions} />
          <Route path="/cookies-policy" component={CookiesPolicy} />
          <PublicRoute exact path="/adminlogin" component={Login} />
          <ProtectedRoute exact path="/dashboard" component={Dashboard} />
          {/* <Route path="/promo" component={Promo} /> */}
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
